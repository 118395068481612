<template>
  <v-text-field
    :value="item.lobsauto"
    clearable
    outlined
    dense
    counter="40"
    maxlength="40"
    @input="onChangeLobsauto($event, item.bistamp)"
  >
    <template v-slot:append-outer>
      <v-btn class="pb-2" icon color="primary" @click="clickSaveLobsauto(item.bistamp)">
        <v-icon>{{ mdiContentSaveCheck }}</v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { mdiClose, mdiContentSaveCheck } from '@mdi/js';

export default {
  emits: ['success'],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    tempValues: {},
    mdiClose,
    mdiContentSaveCheck,
  }),
  computed: {
    ...mapFields(['isLoading', 'alertType']),
  },
  methods: {
    ...mapActions('orders', ['updateProcess']),
    ...mapMutations(['setAlert']),
    onChangeLobsauto(value, bistamp) {
      this.$set(this.tempValues, bistamp, value);
    },
    async clickSaveLobsauto(bistamp) {
      if (this.tempValues[bistamp] === undefined) {
        this.setAlert({ msg: this.$t('wot.success'), alertType: 'success' });
      } else {
        this.isLoading = true;

        await this.updateProcess({ bistamp, data: { lobsauto: this.tempValues[bistamp] } });

        if (this.alertType === 'success') {
          this.$emit('success');
        }

        this.isLoading = false;
      }
    },
  },
};
</script>
